body {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Light"), local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"), local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Medium"), local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-semibold"), local("Poppins-semibold"),
    url("./fonts/Poppins-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

// Here you can add other styles
.c-sidebar {
  background: #016313;
}

.c-sidebar-nav-link {
  color: white !important;
}

.c-sidebar-nav-link.c-active {
  color: #283042 !important;
  background: #f8ab03 !important;
}

.c-sidebar-nav-icon.c-active {
  color: #000000 !important;
  background: white !important;
}

.c-header-nav-link.active {
  color: #016313 !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: rgb(0 0 21 / 20%);
  opacity: 0.9;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #283042;
}

.page-item.active.page-link {
  background: #000000 !important;
  border: #000000 !important;
}

.hand {
  background-color: #016313;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-sidebar-brand.d-md-down-none {
  background: #016313;
}

.c-sidebar-brand.d-md-down-none.active {
  background: #016313;
}

.c-sidebar-minimizer.c-d-md-down-none {
  background: #016313;
}

.c-sidebar.c-sidebar-minimizer:hover {
  background: #016313 !important;
}

.floating {
  padding: 5px;
  align-items: center;
}

.deliveryboyimage {
  background-repeat: no-repeat;
  object-fit: contain;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin: 10px;
  // text-align: center;
}

.licence {
  text-align: center;
  padding: 5px;
  padding: inherit;
}

.deliverycard {
  padding: 20px;
  border-radius: 15px;
  -webkit-box-shadow: 1px 2px 2px -2px #777;
  -moz-box-shadow: 1px 2px 2px -2px #777;
  box-shadow: 1px 2px 2px -2px #777;
}

.deliverydelete {
  position: absolute;
  margin-top: 10px;
  right: 0px;
  margin-right: 20px;
}

.deliveryedit {
  position: absolute;
  margin-top: 10px;
  right: 0px;
  margin-right: 60px;
}

.deliverycol {
  padding: 10px;
}
